import { NavbarRouteProps } from "../props"

const NavbarRoutes: NavbarRouteProps[] = [
  {
    route: "/",
    name: "HOME",
  },
  {
    route: "/team",
    name: "TEAM",
  },
  // {
  //   route: "/portal",
  //   name: "APPLY",
  // },
]

export default NavbarRoutes
